import moment from "moment";

import { failureNotification, successNotification } from "../../utils";
import api from "../api";
// import ApiException from '../exception/ApiException';

const gerarRelatorioSolicitacao = async (values) => {
  try {
    const payload = {};
    payload.relatorio_detalhado = values.relatorio_detalhado || false;
    payload.ordem_alfabetica = values.ordem_alfabetica || false;
    if (values.datas) {
      payload.data_inicio = moment(values?.datas[0]).format("yyyy-MM-DD");
      payload.data_fim = moment(values?.datas[1]).format("yyyy-MM-DD");
    }

    if (values.datas_lancamento) {
      payload.data_lancamento_inicio = moment(
        values?.datas_lancamento[0]
      ).format("yyyy-MM-DD");
      payload.data_lancamento_fim = moment(values?.datas_lancamento[1]).format(
        "yyyy-MM-DD"
      );
    }

    if (values.paciente_id) {
      payload.paciente_id = values.paciente_id;
    }
    if (values.profissional_id) {
      payload.profissional_id = values.profissional_id;
    }
    if (values.clinica_id) {
      payload.clinica_id = values.clinica_id;
    }

    if (values.paciente) {
      values.paciente = JSON.parse(values.paciente);
      payload.paciente_id = values.paciente.id;
      payload.paciente_nome = values.paciente.pessoa_fisica.nome;
    }
    if (values.profissional) {
      values.profissional = JSON.parse(values.profissional);
      payload.profissional_id = values.profissional.id;
      payload.profissional_nome = values.profissional.pessoa_fisica.nome;
    }
    if (values.unidade) {
      values.unidade = JSON.parse(values.unidade);
      payload.unidade_id = values.unidade.id;
      payload.unidade_nome = values.unidade.pessoa_juridica.nome;
    }
    if (values.clinica) {
      values.clinica = JSON.parse(values.clinica);
      payload.clinica_id = values.clinica.id;
      payload.clinica_nome = values.clinica.pessoa_juridica.nome;
    }
    if (values.profissional_clinica) {
      values.profissional_clinica = JSON.parse(values.profissional_clinica);
      payload.profissional_clinica_id = values.profissional_clinica.id;
      payload.profissional_clinica_nome =
        values.profissional_clinica.profissional;
    }
    if (values.procedimento) {
      values.procedimento = JSON.parse(values.procedimento);
      payload.procedimento_id = values.procedimento.id;
      payload.procedimento_nome = values.procedimento.nome;
    }
    if (values.numero) {
      payload.numero = values.numero;
    }
    const { data } = await api.post("relatorios/solicitacao", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    console.log(error);
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioSolicitacaoMapaAtendimento = async (values) => {
  try {
    const payload = {};
    payload.relatorio_detalhado = values.relatorio_detalhado || false;
    payload.ordem_alfabetica = values.ordem_alfabetica || false;
    if (values.datas) {
      payload.data_inicio = moment(values?.datas[0]).format("yyyy-MM-DD 00:00:00");
      payload.data_fim = moment(values?.datas[1]).format("yyyy-MM-DD 23:59:59");
    }
    if (values.datas_lancamento) {
      payload.data_lancamento_inicio = moment(
        values?.datas_lancamento[0]
      ).format("yyyy-MM-DD");
      payload.data_lancamento_fim = moment(values?.datas_lancamento[1]).format(
        "yyyy-MM-DD"
      );
    }

    if (values.paciente_id) {
      payload.paciente_id = values.paciente_id;
    }
    if (values.profissional_id) {
      payload.profissional_id = values.profissional_id;
    }
    if (values.clinica_id) {
      payload.clinica_id = values.clinica_id;
    }

    if (values.paciente) {
      values.paciente = JSON.parse(values.paciente);
      payload.paciente_id = values.paciente.id;
      payload.paciente_nome = values.paciente.pessoa_fisica.nome;
    }
    if (values.profissional) {
      values.profissional = JSON.parse(values.profissional);
      payload.profissional_id = values.profissional.id;
      payload.profissional_nome = values.profissional.pessoa_fisica.nome;
    }
    if (values.unidade) {
      values.unidade = JSON.parse(values.unidade);
      payload.unidade_id = values.unidade.id;
      payload.unidade_nome = values.unidade.pessoa_juridica.nome;
    }
    if (values.clinica) {
      values.clinica = JSON.parse(values.clinica);
      payload.clinica_id = values.clinica.id;
      payload.clinica_nome = values.clinica.pessoa_juridica.nome;
    }
    if (values.profissional_clinica) {
      values.profissional_clinica = JSON.parse(values.profissional_clinica);
      payload.profissional_clinica_id = values.profissional_clinica.id;
      payload.profissional_clinica_nome =
        values.profissional_clinica.profissional;
    }
    if (values.procedimento) {
      values.procedimento = JSON.parse(values.procedimento);
      payload.procedimento_id = values.procedimento.id;
      payload.procedimento_nome = values.procedimento.nome;
    }
    if (values.numero) {
      payload.numero = values.numero;
    }
    const { data } = await api.post("relatorios/solicitacao/mapaatendimentos", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    console.log(error);
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioAtendimento = async (values) => {
  try {
    const payload = {};

    if (values.datas_lancamento) {
      payload.data_lancamento_inicio = moment(
        values?.datas_lancamento[0]
      ).format("yyyy-MM-DD");
      payload.data_lancamento_fim = moment(values?.datas_lancamento[1]).format(
        "yyyy-MM-DD"
      );
    }

    if (values.paciente_id) {
      payload.paciente_id = values.paciente_id;
    }
    if (values.paciente) {
      values.paciente = JSON.parse(values.paciente);
      payload.paciente_id = values.paciente.id;
      payload.paciente_nome = values.paciente.pessoa_fisica.nome;
    }
    if (values.profissional) {
      values.profissional = JSON.parse(values.profissional);
      payload.profissional_id = values.profissional.id;
      payload.profissional_nome = values.profissional.pessoa_fisica.nome;
    }

    if (values.numero) {
      payload.numero = values.numero;
    }
    const { data } = await api.post("relatorios/atendimento", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioDistritos = async () => {
  try {
    const { data } = await api.get("relatorios/distrito", {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioPacientes = async (values) => {
  try {
    const payload = {};

    if (values.distrito) {
      values.distrito = JSON.parse(values.distrito);
      payload.distrito_id = values.distrito.id;
      payload.distrito_nome = values.distrito.nome;
    }
    if (values.nome) {
      payload.nome = values.nome;
    }
    if (values.cidade) {
      payload.cidade = values.cidade;
    }
    if (values.sexo) {
      payload.sexo = values.sexo;
    }
    if (values.estado_civil) {
      payload.estado_civil = values.estado_civil;
    }

    const { data } = await api.post("relatorios/paciente", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioProfissionais = async (values) => {
  try {
    const payload = {};

    if (values.nome) {
      payload.nome = values.nome;
    }
    if (values.cidade) {
      payload.cidade = values.cidade;
    }

    if (values.estado_civil) {
      payload.estado_civil = values.estado_civil;
    }

    const { data } = await api.post("relatorios/profissional", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioUnidades = async (values) => {
  try {
    const payload = {};

    if (values.nome) {
      payload.nome = values.nome;
    }
    if (values.cidade) {
      payload.cidade = values.cidade;
    }

    const { data } = await api.post("relatorios/unidade", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioClinicas = async (values) => {
  try {
    const payload = {};

    if (values.nome) {
      payload.nome = values.nome;
    }
    if (values.cidade) {
      payload.cidade = values.cidade;
    }

    const { data } = await api.post("relatorios/clinica", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarDashboardSolicitacao = async () => {
  const { data } = await api.get("/dashboard/solicitacao");
  return data;
};

const gerarDashboardPaciente = async () => {
  const { data } = await api.get("/dashboard/pacientes");
  return data;
};
const gerarDashboardProfissionais = async () => {
  const { data } = await api.get("/dashboard/profissionais");
  return data;
};
const gerarDashboardAtendimentos = async () => {
  const { data } = await api.get("/dashboard/atendimentos");
  return data;
};

const gerarRelatorioProcedimentos = async (values) => {
  try {
    const payload = {};

    if (values.datas) {
      payload.ordena_mes = values.ordena_mes || false;
      payload.data_inicio = moment(values?.datas[0]).format("yyyy-MM-DD");
      payload.data_fim = moment(values?.datas[1]).format("yyyy-MM-DD");
    }
    const { data } = await api.post("relatorios/procedimentos", payload, {
      responseType: "arraybuffer",
    });
    const file = new Blob([data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  } catch (error) {
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
};

const gerarRelatorioQuotas = async (values) =>{
  try{
    const payload ={};
    if(values.mes){
      payload.mes = values.mes;
      payload.ano = values.ano;
    }
    const {data} = await api.post("relatorios/quotas",payload,{responseType: "arraybuffer"});
    const file = new Blob([data],{type: "application/pdf"});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
    successNotification("Relatório gerado com sucesso!");
  }catch(error){
    failureNotification("Ocorreu um erro ao gerar o relatório");
  }
}

export {
  gerarRelatorioProcedimentos,
  gerarRelatorioSolicitacao,
  gerarRelatorioClinicas,
  gerarRelatorioProfissionais,
  gerarRelatorioUnidades,
  gerarRelatorioDistritos,
  gerarRelatorioAtendimento,
  gerarRelatorioPacientes,
  gerarDashboardSolicitacao,
  gerarDashboardPaciente,
  gerarDashboardProfissionais,
  gerarDashboardAtendimentos,
  gerarRelatorioQuotas,
  gerarRelatorioSolicitacaoMapaAtendimento
};
